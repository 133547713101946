/**
 * PRIVATE CONST for BE api
 */
const apiKey = 'u380114oTahF'

/**
 * Environment
 */
export const environment = 'preview' // 'live'

// dev
const credential = 'console'
const secret = 'hEcwiKG0MJMsqZWrqWBNjZ6MdGK2JENl'
// prod
// const credential = 'website'
// const secret = 'FmML5oPd652v5AiT6F48T6P0isXvjfST'
const baseURL = `https://${apiKey}.${environment}.gamesparks.net/rs/${credential}/${secret}/`
const apiURL = 'https://api-dev.projectgenesis.com/'

/**
 * URLS
 */
export const URL = {
  TRELLO_GET_BOARD: 'https://trello.com/1/board/',
  TRELLO_GET_CARDS: 'https://trello.com/1/lists/',
  AUTH_LOGIN: `${baseURL}AuthenticationRequest`,
  AUTH_REGISTARTION: `${baseURL}RegistrationRequest`,
  AUTH_PASSWORD_RECOVERY: `${apiURL}passwordRecoveryRequest`,
  AUTH_PASSWORD_RESET: `${apiURL}passwordReset`,
  PROFILE_PLAYER: `${baseURL}AccountDetailsRequest`,
  EVENT: `${baseURL}LogEventRequest`,
  ACCOUNT_CHANGE_DETAILS: `${baseURL}ChangeUserDetailsRequest`,
  LEADERBOARDS_BASE: `${apiURL}leaderboard/`,
  LEADERBOARDS_LIST: `${apiURL}leaderboards`,
  SEARCH_USERS: `${apiURL}findUsers`,
  PUBLIC_PROFILE_BASE: `${apiURL}getUser/`,
  PUBLIC_PROFILE_STATS_BASE: `${apiURL}score/`,
  ONE_STATS: `${apiURL}getStat`,
  CONTACT_SEND_EMAIL: `${apiURL}sendContactEmail`,

  AUTH_LOGIN_WITH_GOOGLE: `${apiURL}web/authUserWithGoogle`,
  AUTH_LOGIN_WITH_STEAM: `${apiURL}web/authUserWithSteam`,
  AUTH_LOGIN_WITH_STEAM_REDIRECT: `${apiURL}web/authSteamRedirect`,
  AUTH_ASSIGN_STEAM_CODE: `${apiURL}web/assignSteamKeyCode`,
  AUTH_LINK_TWITCH_DATA: `${apiURL}web/linkTwitchUser`
}

/**
 *
 * @param url {string} - request url
 * @param params {RequestInit} request body
 */
export function httpRequest (url: string, params: RequestInit | undefined) {
  return fetch(url, params).then(response => response.json())
}
